export const formatDate = (date: Date, timeZone: string) => {
  const formatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZone: timeZone,
  });
  const formattedDateParts = formatter.formatToParts(date);

  let dateObj = {
    month: "",
    day: "",
    year: "",
    hour: "",
    minute: "",
    second: "",
    literal: "",
  };
  formattedDateParts.forEach(({ value, type }) => {
    // @ts-ignore
    dateObj[type] = value;
  });

  return `${dateObj.year}-${dateObj.month}-${dateObj.day} ${dateObj.hour}:${dateObj.minute}:${dateObj.second}`;
};

// Create SHA-256 hash with predefined salt
export async function createSHA256WithSalt(input: string, salt: string) {
  const inputBuffer = stringToArrayBuffer(input);
  const saltBuffer = stringToArrayBuffer(salt);
  const combinedBuffer = concatenateArrayBuffers(saltBuffer, inputBuffer);

  const hashBuffer = await window.crypto.subtle.digest(
    "SHA-256",
    combinedBuffer,
  );
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
}

// Convert a string to an ArrayBuffer
function stringToArrayBuffer(str: string) {
  const encoder = new TextEncoder();
  return encoder.encode(str);
}

// Concatenate two ArrayBuffers
function concatenateArrayBuffers(buffer1: Uint8Array, buffer2: Uint8Array) {
  const tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp.buffer;
}
