import React, { useEffect } from "react";
import "./App.css";
import { Chat } from "./ui/Chat";
import { loadGoogleMaps } from "./services/GoogleMapsLoader";
import {
  LeadTimeResponse,
  MapsErrorResponse,
  WEB_ROOT,
} from "./services/ApiService";

export type IPDataType = {
  country_code: string;
  country_name: string;
  city: string;
  latitude: number;
  longitude: number;
  IPv4: string;
  eu: string;
  region: string;
  timezone: string;
};

function App() {
  useEffect(() => {
    if (window.location.hostname.startsWith("davel"))
      loadGoogleMaps("AIzaSyAHDamqpBaLnuNi9xPWViBhcKCd5Japirw").then(
        console.log,
      );
    //window.location.hostname.startsWith('davel') ? 'AIzaSyAHDamqpBaLnuNi9xPWViBhcKCd5Japirw' : 'AIzaSyBzwT19VQmVUXsLWARUoNMNrg8hx-BMvRg'
    else
      loadGoogleMaps("AIzaSyBzwT19VQmVUXsLWARUoNMNrg8hx-BMvRg").then(
        console.log,
      );
  }, []);

  const dedicatedPage = window.location.pathname === "/vagent";

  fetch(`https://davelbostoncoach.com/ip/`)
    .then((response) => response.json())
    .then((data) =>
      sessionStorage.setItem("user_ip_data", JSON.stringify(data)),
    );

  // const queryParameters = new URLSearchParams(window.location.search);
  const previousState = sessionStorage.getItem("vagent-closed") !== "true";
  // if (queryParameters.get("vagent") === "true") {
  if (!dedicatedPage)
    return (
      <div className="App">
        <Chat isOpen={previousState} />
      </div>
    );
  // }

  return (
    <div className="App">
      <header className="App-header">
        <Chat isOpen={previousState} />
      </header>
    </div>
  );
}

export default App;
