import posthog from "posthog-js";

const sendSmartLookEvent = (
  eventName:
    | "vagent-open"
    | "vagent-closed"
    | "vagent-pricing"
    | "vagent-state",
  eventProperties: Record<string, any>,
) => {
  console.log("SmartLook sendSmartLookEvent");
  SmartLook()("vagent", eventName, { ...eventProperties, source: "vagent" });
  posthog.capture(eventName, { ...eventProperties, source: "vagent" });
};

const SmartLook = () => {
  return (window as any).smartlook
    ? (window as any).smartlook
    : (type: string, name: string, value: any) => {
        console.log("SmartLook log:", type, name, value);
      };
};

export { sendSmartLookEvent };
