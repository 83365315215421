import "./driver-instructions-input.css";
import { useState } from "react";
import { Button } from "./Button";

export type DriverInstructionsInputType = {
  setDriverInstructions: (instructions: string) => void;
  driverInstructions: string;
};

export const DriverInstructions: React.FC<DriverInstructionsInputType> = ({
  setDriverInstructions: submitDriverInstructions,
  driverInstructions,
}) => {
  const [inputValue, setInputValue] = useState(driverInstructions);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value);
  }

  const isDisabled = inputValue.length > 0;
  return (
    <div className="driver-input-block">
      <div className="driver-input-block-relative">
        <input
          type="text"
          className="driver-input"
          value={inputValue}
          onChange={handleChange}
          placeholder="Enter special instructions"
        />
        <div
          className="svg-submit-button"
          onClick={() => submitDriverInstructions(inputValue)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M3.98836 10.7134L3.98837 10.7134L3.987 10.7129C3.89802 10.6839 3.83041 10.61 3.80776 10.5177C3.80776 10.5177 3.80775 10.5177 3.80775 10.5177L1.78746 2.26307C1.78746 2.26306 1.78745 2.26302 1.78745 2.26302C1.75114 2.11429 1.84273 1.96523 1.99007 1.92874C2.05502 1.91298 2.12262 1.92021 2.1809 1.94934C2.18092 1.94934 2.18094 1.94935 2.18095 1.94936L21.786 11.7788C21.7861 11.7789 21.7862 11.7789 21.7862 11.779C21.9227 11.8482 21.9788 12.014 21.9118 12.1536C21.8828 12.2074 21.8388 12.2517 21.7876 12.2764L21.7865 12.277L2.18183 22.05C2.18174 22.0501 2.18164 22.0501 2.18155 22.0501C2.04132 22.1183 1.87532 22.0616 1.80829 21.9258L1.8083 21.9258L1.80753 21.9243C1.77915 21.8692 1.77159 21.8021 1.78745 21.7368C1.78746 21.7367 1.78747 21.7367 1.78748 21.7367L3.81245 13.4704L3.81246 13.4704L3.81284 13.4687C3.83313 13.3791 3.89929 13.3064 3.99086 13.2746C3.99089 13.2746 3.99091 13.2746 3.99094 13.2746L7.4501 12.0864L7.71907 11.994L7.45008 11.9017L3.98836 10.7134ZM3.90867 19.3419L3.85862 19.5465L4.0471 19.4525L18.7705 12.1119L18.9453 12.0248L18.7707 11.9372L4.05199 4.55675L3.86317 4.46207L3.91336 4.66725L5.08758 9.46725L5.10026 9.51911L5.15077 9.53642L12.0683 11.9079C12.0685 11.9079 12.0687 11.908 12.0688 11.9081C12.1173 11.9257 12.1415 11.9766 12.1257 12.0241C12.1172 12.0497 12.0964 12.0704 12.0692 12.0799C12.0692 12.0799 12.0691 12.0799 12.0691 12.0799L5.15074 14.454L5.10026 14.4713L5.08758 14.5232L3.90867 19.3419Z"
              fill="#862633"
              stroke="#862633"
              stroke-width="0.195312"
            />
          </svg>
        </div>
      </div>
      <Button
        type="secondary"
        width="auto"
        className="skip-button"
        text={"Skip"}
        disabled={isDisabled}
        onClick={!isDisabled ? () => submitDriverInstructions("") : () => {}}
      />
    </div>
  );
};
