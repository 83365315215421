import { BookingState, MessageType } from "./Chat";

import "./message.css";
import { BookingFlowState, statusToText } from "../services/ChatStateMachine";

export const UPDATE_MESSAGE_PREFIX = "Please update my message: ";

export const Message: React.FC<
  MessageType & {
    isFirst: boolean;
    isLast: boolean;
    onEdit: (
      message: string[],
      bookingState: Partial<BookingState>,
      stateOverride: BookingFlowState,
    ) => void;
    nextStatus: BookingFlowState;
  }
> = ({ content, sender, editable, isFirst, isLast, nextStatus, onEdit }) => {
  const messageType = sender === "BOT" ? "received" : "sent";
  const messagePosition = isFirst ? "first" : isLast ? "last" : "middle";
  const edit = () => {
    console.log("Edit message", nextStatus);
    let bookingState = {} as Partial<BookingState>;
    if (
      [
        BookingFlowState.EDIT_POINT_A,
        BookingFlowState.INPUT_POINT_A,
        BookingFlowState.INPUT_POINT_A_AIRPORT,
        BookingFlowState.INPUT_POINT_B,
        BookingFlowState.INPUT_POINT_B_AIRPORT,
      ].includes(nextStatus)
    ) {
      bookingState = {
        flightInfo: undefined,
        flightArrivalDate: undefined,
        flightDepartureDate: undefined,
        flightNumber: undefined,
        airlineCode: undefined,
      };
    }
    onEdit([`${UPDATE_MESSAGE_PREFIX}${content}`], bookingState, nextStatus);
  };

  const isEditable = () => {
    if (content.indexOf(UPDATE_MESSAGE_PREFIX) === 0) return false;
    switch (nextStatus) {
      case BookingFlowState.EDIT_POINT_A:
      case BookingFlowState.INPUT_POINT_A:
      case BookingFlowState.INPUT_POINT_A_AIRPORT:
      case BookingFlowState.INPUT_POINT_B:
      case BookingFlowState.INPUT_POINT_B_AIRPORT:
      case BookingFlowState.PICK_DATE_TIME:
      case BookingFlowState.AIRPORT_PICK_DATE_TIME:
      case BookingFlowState.FLIGHT_ARRIVAL_DATE:
      case BookingFlowState.FLIGHT_DEPARTURE_DATE:
      case BookingFlowState.AIRLINE_CODE_INPUT:
      case BookingFlowState.FLIGHT_NUMBER_INPUT:
      case BookingFlowState.PICK_CAR_CLASS:
      case BookingFlowState.CONFIRM_BOOKING:
      case BookingFlowState.DRIVER_INSTRUCTIONS:
      case BookingFlowState.ENTER_NUMBER_OF_PASSENGERS:
        return editable;
      default:
        return false;
    }
  };

  return (
    <div className={`message-${messageType}`}>
      {isEditable() ? (
        <div className="message-edit-button" onClick={edit}>
          <svg
            fill="#000000"
            height="13px"
            width="13px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <g>
              <g>
                <path
                  d="M495.807,66.962L444.696,15.85c-21.136-21.134-55.53-21.134-76.666,0.001l-29.815,29.815L31.926,351.956L0.341,512
			l159.396-32.304l336.069-336.069C516.943,122.49,516.943,88.099,495.807,66.962z M338.215,96.776l25.557,25.556l-264.07,264.07
			l-25.557-25.555L338.215,96.776z M46.294,465.811l13.468-68.239l54.471,54.471L46.294,465.811z M150.812,437.512l-25.555-25.555
			l264.07-264.07l25.555,25.555L150.812,437.512z M470.252,118.072l-29.815,29.815L363.77,71.221l29.813-29.814
			c7.05-7.047,18.514-7.045,25.559-0.001l51.11,51.111C477.296,99.562,477.296,111.027,470.252,118.072z"
                />
              </g>
            </g>
          </svg>
        </div>
      ) : null}
      <div
        className={`message-${messageType}-text message-${messageType}-${messagePosition}`}
        data-testid={`message-${statusToText(nextStatus)}`}
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </div>
  );
};
