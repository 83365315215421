import { EncodedLocation } from "./GoogleAutocompleteService";
import { BookingState, RideOptions } from "../ui/Chat";
import { createSHA256WithSalt } from "./utils";
import { v4 as uuidv4 } from "uuid";
import { AirlineType } from "../ui/chat-elements/AirlineInput";
import { FlightSearchPayload } from "../ui/chat-elements/FlightNumberInput";
import moment from "moment-timezone";
import { LEAD_TIME_ADDITIONAL, TIMEZONE } from "./Constants";
import {
  getGlAirportDropoffLocation,
  getGlAirportPickupLocation,
  getUserAndProfile,
} from "./MiniBookerTransform";

export type LeadTimeResponse = {
  vehicle_type: "SEDN" | "SUV" | "LUX";
  lead_time: number;
  current_time: string;
  zone_lead_time: number;
  earliest_pickup_time: string;
};

export type MapsErrorResponse = {
  code: string;
  message: string;
};

export type ProfileInfo = {
  first_name: string;
  last_name: string;
  email: string;
  type: string;
  push_notification_enabled: boolean;
  id: string;
  sort_name: string;
  status: string;
  active: boolean;
  pref_car_class_txt: string;
  mobile_number: string;
  display_loyalty_rewards: boolean;
  customer_id: string;
  profile_notes: string;
  select_all_accounts: boolean;
  iata_number: string;
  authorized_wait_applicable: boolean;
};

const isQA =
  window.location.origin.indexOf("localhost") > -1 ||
  window.location.origin.indexOf("dev.") > -1;

export const WEB_ROOT = isQA
  ? "https://dev.davelbostoncoach.com"
  : window.location.origin;

export const ONDEMAND_API_ROOT = `https://ondemand${isQA ? "-dev" : ""}.davelbostoncoach.com`;

export const getProfileInfo = () => {
  const authToken =
    localStorage.getItem("authentication_token") ||
    sessionStorage.getItem("authentication_token");

  const authorization = authToken
    ? { Authorization: `Token ${authToken}` }
    : undefined;

  if (!authToken) throw new Error("No auth token found");

  return fetch(`${ONDEMAND_API_ROOT}/OnDemand/website/profile-info`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "integration-channel": "VAGENT",
      ...authorization,
    },
  })
    .then((response) => {
      if (response.ok) return response.json();
      else {
        if (response.status === 401) {
          localStorage.removeItem("authentication_token");
          sessionStorage.removeItem("authentication_token");
        }
        throw new Error("No auth token found");
      }
    })
    .then((data: ProfileInfo) => {
      if (!sessionStorage.getItem("auth_user_details") && data)
        sessionStorage.setItem("auth_user_details", JSON.stringify(data));
      return data;
    })
    .catch((error) => {
      console.error("Error:", error);
      sessionStorage.removeItem("auth_user_details");
      return null;
    });
};

export const getLeadTime = (
  pickupAddress: EncodedLocation,
): Promise<LeadTimeResponse | null> => {
  return fetch(`${WEB_ROOT}/api/website/lead-time`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "integration-channel": "VAGENT",
    },
    body: JSON.stringify({
      pickup: pickupAddress,
    }),
  })
    .then((response) => response.json())
    .then((data: LeadTimeResponse[] | MapsErrorResponse) => {
      if ("code" in data) {
        throw Error(data.code + ": " + data.message);
      }

      data.map((leadTime: LeadTimeResponse) => {
        let date = new Date(leadTime.earliest_pickup_time);
        let minutes = date.getMinutes();
        let roundedMinutes = roundUpToNearestFive(
          minutes + LEAD_TIME_ADDITIONAL,
        );
        date.setMinutes(roundedMinutes);
        date.setSeconds(0);
        date.setMilliseconds(0);
        leadTime.earliest_pickup_time = date.toISOString();
        return leadTime;
      });

      data.sort((a, b) => {
        return (
          new Date(a.earliest_pickup_time).getTime() -
          new Date(b.earliest_pickup_time).getTime()
        );
      });

      return data[0];
    })
    .catch((error) => {
      console.error("Error:", error);
      return new Promise<LeadTimeResponse>((resolve) =>
        resolve({
          vehicle_type: "SEDN",
          lead_time: 0,
          current_time: "",
          zone_lead_time: 0,
          earliest_pickup_time: "",
        }),
      );
    });
};

const roundUpToNearestFive = (n: number) => {
  return Math.ceil(n / 5) * 5;
};

export const getAvailableCarClasses = async (): Promise<string[]> => {
  const accountId = localStorage.getItem("debc_selected_account");
  const authToken =
    localStorage.getItem("authentication_token") ||
    sessionStorage.getItem("authentication_token");

  const authorization = authToken
    ? { Authorization: `Token ${authToken}` }
    : undefined;

  return fetch(
    `${ONDEMAND_API_ROOT}/OnDemand/website/vehicle-type-list/code${accountId ? `/${accountId}` : ""}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "integration-channel": "VAGENT",
        ...authorization,
      },
    },
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
};

export type PricingRequestType = {
  car_classes: string[];
  date: string;
  time_zone: string;
  payment_type: "CREDIT_CARD";
  destination_location: EncodedLocation;
  origin_location: EncodedLocation;
  luggage_count: 0;
  number_of_passengers: number;
  round_trip: boolean;
  use_credits: boolean;
  stops: EncodedLocation[];
  account_code: null | string;
  account_id: null | string;
  customer_id: null | string;
  ppc_ref_id: null | string;
  promo_code: null | string;
  session_id: string;
};

export type ChargeType = {
  item_id: string;
  caption: string;
  value_text: string;
  display_order: number;
  sequence_number: number;
  value: string;
};

export type PricingReceiptType = {
  id: number;
  car_class: string;
  base_charges: ChargeType[];
  sorted_charges: ChargeType[];
  job_id: number;
  discount: string;
  surge_description: string;
  total: string;
  is_hourly: boolean;
};

export type PricingResponseType = {
  receipt?: PricingReceiptType;
  merchandise_car_class: string;
  merchandise_car_class_desc: string;
  merchandise_car_class_desc_long: string;
  car_class: string;
  pax_capacity: string;
  luggage_capacity: string;
  image_url: string;
  status: string;
  status_description: string;
  market_number: number;
  medium_image_url: string;
  number_of_child_seats: number;
  car_capacity_disclaimer: string;
  child_seat_capacity_disclaimer: string;
  sort: number;
};

export const getPricing = async (
  pricingRequest: PricingRequestType,
): Promise<PricingResponseType[]> => {
  const authUserDetails = sessionStorage.getItem("auth_user_details");
  const selectedAccountId = localStorage.getItem("debc_selected_account");
  const authToken =
    localStorage.getItem("authentication_token") ||
    sessionStorage.getItem("authentication_token");
  let customerId: string | undefined = undefined;
  let accountId: string | undefined = undefined;
  if (authUserDetails) {
    try {
      const parsedUserDetails = JSON.parse(authUserDetails) as UserDetailsType;
      customerId = parsedUserDetails.customer_id;
      accountId = selectedAccountId || parsedUserDetails.profile_id;
    } catch (e) {}
  }

  pricingRequest.customer_id = customerId || null;
  pricingRequest.account_id = accountId || null;
  pricingRequest.account_code = accountId || null;

  const rideArrayParam = "VaPp.." + uuidv4().substring(0, 8);
  // const miniBookerEditKey = "VaMb.." + uuidv4().substring(0, 8);
  const browserId = window.uuid || "-";
  const key = await createSHA256WithSalt(rideArrayParam, browserId);

  const isSignedIn = !!getUserAndProfile();

  let pricingType = "private";
  if (isSignedIn) pricingType = "user";

  const authorization = authToken
    ? { Authorization: `Token ${authToken}` }
    : undefined;

  return fetch(
    `${WEB_ROOT}/api/website/${pricingType}/pricing?ra=${rideArrayParam}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "integration-channel": "VAGENT",
        "browser-id": `${key}.${browserId}`,
        ...authorization,
      },
      body: JSON.stringify(pricingRequest),
    },
  )
    .then((response) => response.json())
    .then((data: PricingResponseType[]) => {
      return data;
    })
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
};

export type UserDetailsType = {
  first_name: string;
  last_name: string;
  email: string;
  type: string;
  push_notification_enabled: boolean;
  id: string;
  sort_name: string;
  status: string;
  active: boolean;
  pref_car_class_txt: string;
  mobile_number: string;
  display_loyalty_rewards: boolean;
  customer_id: string;
  select_all_accounts: boolean;
  iata_number: string;
  authorized_wait_applicable: boolean;
  profile_id: string;
  corporate_user: string;
};

export const createBooking = (
  bookingStateparams: BookingState,
  rideOptions: RideOptions,
  timeZone: string,
) => {
  const {
    date,
    pickupAddress,
    dropoffAddress,
    carClass,
    airline,
    flightInfo,
    numberOfPassengers,
  } = bookingStateparams;

  let customerId: string | undefined = undefined;
  let accountId: string | undefined = undefined;
  let time_zone: string = timeZone;

  try {
    const parsedUserDetails = getUserAndProfile();
    customerId = parsedUserDetails?.customer_id;
    accountId = parsedUserDetails?.profile_id;
    const userLocation = sessionStorage.getItem("user_ip_location");
    if (!time_zone && userLocation) {
      const parsedLocation = JSON.parse(userLocation);
      time_zone = parsedLocation.timeZoneId;
    }
    if (accountId) {
      sessionStorage.setItem("debc_selected_profile", accountId);
      sessionStorage.setItem("debc_selected_profile_guest", "false");
    }
  } catch (e) {}

  const formattedDate = moment.tz(date, timeZone).format("YYYY-MM-DDTHH:mm:ss");

  const queryParams = new URLSearchParams(window.location.search);

  const bookingObj = [
    {
      stops: [],
      date: formattedDate,
      active: true,
      luggage_count: 0,
      round_trip: false,
      origin_location: pickupAddress,
      number_of_passengers: numberOfPassengers,
      destination_location: dropoffAddress,
      payment_type: "CREDIT_CARD",
      promo_code: null,
      child_seats: [],
      use_credits: false,
      customer_id: customerId,
      ppc_ref_id: null,
      account_id: accountId,
      account_code: accountId,
      time_zone: time_zone,
      session_id: `vagent-${uuidv4().substring(0, 8)}`,
    },
  ];
  // const pricingParam = uuidv4().substring(0, 8);
  const pricingParam = queryParams.get("ra") || uuidv4().substring(0, 8);
  sessionStorage.setItem(pricingParam, JSON.stringify(bookingObj));

  const flightParam = queryParams.get("bf") || uuidv4().substring(0, 8);
  if (airline) {
    sessionStorage.setItem(flightParam, JSON.stringify(airline));
  }

  const flightInfoParam = queryParams.get("fi") || uuidv4().substring(0, 8);
  if (flightInfo) {
    sessionStorage.setItem(flightInfoParam, JSON.stringify(flightInfo));
  }

  sessionStorage.setItem("ro", JSON.stringify(rideOptions));

  const miniBookerEditParamLocalhost = uuidv4().substring(0, 8);

  const isLocalhost = window.location.host.indexOf("localhost") > -1;

  const miniBookerObjectParam = isLocalhost
    ? miniBookerEditParamLocalhost
    : queryParams.get("mbd");

  if (miniBookerObjectParam != null) {
    if (!isLocalhost) {
      const miniBookerObject = JSON.parse(
        sessionStorage.getItem(miniBookerObjectParam) || "",
      );

      miniBookerObject.state.mode = "MODE__EXPANDED";
      miniBookerObject.state.addFlightMode = false;
      miniBookerObject.state.editMode = false;
      if (flightInfo) {
        miniBookerObject.state.bookingFlight = airline;
        miniBookerObject.containers.AirportPickupOptionsContainer = {
          error: {},
          fetched: true,
          fetching: false,
          data: null,
        };
        miniBookerObject.containers.AirlineSearchContainer = {
          error: null,
          fetched: true,
          fetching: false,
          data: [airline],
        };
        miniBookerObject.containers.FlightsInfoContainer = {
          error: null,
          fetched: true,
          fetching: false,
          data: [flightInfo],
        };
        miniBookerObject.state.flightInfo = flightInfo;
      }
      if (pickupAddress?.iata)
        miniBookerObject.components.GlAirportPickupLocation =
          getGlAirportPickupLocation(
            date,
            timeZone,
            flightInfo,
            airline,
            pickupAddress,
          );
      if (dropoffAddress?.iata)
        miniBookerObject.components.GlAirportDropoffLocation =
          getGlAirportDropoffLocation(
            date,
            timeZone,
            flightInfo,
            airline,
            formattedDate,
          );
      sessionStorage.setItem(
        miniBookerObjectParam,
        JSON.stringify(miniBookerObject),
      );
    }

    const query = `vagent=true&ra=${pricingParam}&mbd=${miniBookerObjectParam}&scc=${carClass?.toLowerCase()}${airline ? `&bf=${flightParam}` : ""}${flightInfo ? `&fi=${flightInfoParam}` : ""}`;
    window.history.replaceState(
      null,
      "",
      `${window.location.href.split("?")[0]}?${query}`,
    );
    window.location.href = `/booking/select-payment?${query}`;
  } else {
    setTimeout(
      () => createBooking(bookingStateparams, rideOptions, timeZone),
      1000,
    );
  }
};

type AirportSuggestionType = AirportType & {
  address_type: "AIRPORT";
  locality: string;
  address: string;
  region: string;
  partial_match: false;
};
export type SuggestAirportsResponseType = {
  nearby_airports: AirportSuggestionType[];
};

export const fetchAirports = async (
  lat: number,
  lng: number,
): Promise<SuggestAirportsResponseType | null> => {
  // https://ondemand.davelbostoncoach.com/OnDemand/website/locations/suggest?lng=-73.9719906&lat=40.7677061
  return fetch(
    `${ONDEMAND_API_ROOT}/OnDemand/website/locations/suggest?lng=${lng}&lat=${lat}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "integration-channel": "VAGENT",
      },
    },
  )
    .then((response) => response.json())
    .then((data: SuggestAirportsResponseType) => {
      return data;
    })
    .catch((error) => {
      console.error("Error:", error);
      return null;
    });
};

export type AirportType = {
  iata: string;
  name: string;
  city: string;
  state: string;
  country: string;
  latitude: number;
  longitude: number;
  iata_code: string;
};

export const isLocationAirport = async (
  address: EncodedLocation,
): Promise<EncodedLocation> => {
  return fetch(
    `${ONDEMAND_API_ROOT}/OnDemand/locations/airports/nearby?latitude=${address.latitude}&longitude=${address.longitude}&address=${address.address}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "integration-channel": "VAGENT",
      },
    },
  )
    .then((response) => {
      if (response.ok) return response.json();
      else return null;
    })
    .then((data: AirportType) => {
      address.iata = data.iata;
      address.address = `(${data.iata}) ${data.name}`;
      address.address_type = "AIRPORT";
      address.longitude = data.longitude;
      address.latitude = data.latitude;

      return address;
    })
    .catch((error) => {
      console.debug(error);
      return address;
    });
};

export const getAirlines = async (
  airlineCode: string,
): Promise<AirlineType[]> => {
  // https://ondemand-dev.davelbostoncoach.com/OnDemand/website/airlines-search?query=aa
  return fetch(
    `${ONDEMAND_API_ROOT}/OnDemand/website/airlines-search?query=${airlineCode}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "integration-channel": "VAGENT",
      },
    },
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error:", error);
      return [];
    });
};

export type FlightInfoResponseType = {
  airline: AirlineType;
  arrival_date_time_scheduled_local: "2024-08-23T18:25:00";
  arrival_terminal: "B";
  arrival_time_zone_offset: 0;
  cancelled: boolean;
  delayed: boolean;
  departure_airport: AirportType;
  departure_date_time_scheduled_local: "2024-08-23T18:25:00";
  departure_time_zone_offset: number;
  diverted: boolean;
  flight_number: string;
  holding: boolean;
  in_air: boolean;
  landed: boolean;
  origin_arrival_airport: AirportType;
  scheduled_arrival_time: string;
  scheduled_departure_time: string;
  dep_airport_longitude: number;
  dep_airport_latitude: number;
  arrival_airport_longitude: number;
  arrival_airport_latitude: number;
};

export type FlightInfoErrorType = {
  code: string;
  message: string;
};

export const searchFlights = async (
  flight: FlightSearchPayload,
): Promise<FlightInfoResponseType[] | FlightInfoErrorType> => {
  // https://ondemand-dev.davelbostoncoach.com/OnDemand/website/search-flights
  return fetch(`${ONDEMAND_API_ROOT}/OnDemand/website/search-flights`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "integration-channel": "VAGENT",
    },
    body: JSON.stringify(flight),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error:", error);
      return [];
    });
};

export const getTzFromLatLng = async (
  lat: number | string | undefined,
  lng: number | string | undefined,
) => {
  if (!lat || !lng) {
    return { timezone: "America/New_York" };
  }

  return fetch(`${WEB_ROOT}/api/website/resolve-timezone`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "integration-channel": "VAGENT",
    },
    body: JSON.stringify({
      lat: lat,
      lng: lng,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      sessionStorage.setItem(TIMEZONE, data.timezone);
      return data;
    })
    .catch((error) => {
      console.error("Error:", error);
      return "America/New_York";
    });
};
