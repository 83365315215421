import { BookingState, MessageType, RideOptions } from "../Chat";
import moment from "moment-timezone";

export const getBookingDetailsMessages = (
  bookingState: BookingState,
  rideOptions: RideOptions,
  timeZone: string,
): MessageType[] => {
  const responsePart1 = [
    {
      id: Math.random(),
      content: `Here are your ride details:`,
      sender: "BOT",
    },
    {
      id: Math.random(),
      content: `<b>Pickup address:</b> ${bookingState.pickupAddress?.address}`,
      sender: "BOT",
    },
    {
      id: Math.random(),
      content: `<b>Drop-off address:</b> ${bookingState.dropoffAddress?.address}`,
      sender: "BOT",
    },
    {
      id: Math.random(),
      content: `<b>Date:</b> ${moment.tz(bookingState.date, timeZone).format("MM/DD/YYYY")}`,
      sender: "BOT",
    },
    {
      id: Math.random(),
      content: `<b>Time:</b> ${moment.tz(bookingState.date, timeZone).format("hh:mm a")}`,
      sender: "BOT",
    },
  ] as MessageType[];

  const responsePart2 = [
    {
      id: Math.random(),
      content: `<b>Car class:</b> ${bookingState.merchandiseCarClass}`,
      sender: "BOT",
    },
  ] as MessageType[];

  const priceMessage = {
    id: Math.random(),
    content: `<b>Total:</b> ${bookingState.receipt?.total}`,
    sender: "BOT",
  } as MessageType;

  const flightMessage = [] as MessageType[];
  if (bookingState.flightInfo) {
    const year = moment.tz(bookingState.date, timeZone).format("YYYY");
    const month = moment.tz(bookingState.date, timeZone).format("MM");
    const date = moment.tz(bookingState.date, timeZone).format("DD");
    const daysFromNow = moment
      .tz(bookingState.date, timeZone)
      .diff(moment(), "days");
    const flightUrl =
      "https://www.flightstats.com/v2/flight-tracker/" +
      `${bookingState.flightInfo.airline.code}/${bookingState.flightInfo.flight_number}` +
      (daysFromNow < 4 ? `?year=${year}&month=${month}&date=${date}` : "");

    flightMessage.push({
      id: Math.random(),
      sender: "BOT",
      content: `<b>Flight: </b> ${bookingState.flightInfo.origin_arrival_airport.iata_code} - ${bookingState.flightInfo.departure_airport.iata_code}`,
      editable: false,
    });
    flightMessage.push({
      id: Math.random(),
      content: `<b>Flight number:</b> <a href="${flightUrl}" target="_blank">${bookingState.flightInfo.airline.code}${bookingState.flightInfo.flight_number}</a> 
<span style="margin: 3px;
    display: block;
    float: right;"><svg width="15px" height="15px" viewBox="0 0 512 512">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
        <g id="icon" fill="#ffffff" transform="translate(85.333333, 64.000000)">
            <path d="M128,63.999444 L128,106.666444 L42.6666667,106.666667 L42.6666667,320 L256,320 L256,234.666444 L298.666,234.666444 L298.666667,362.666667 L4.26325641e-14,362.666667 L4.26325641e-14,64 L128,63.999444 Z M362.666667,1.42108547e-14 L362.666667,170.666667 L320,170.666667 L320,72.835 L143.084945,249.751611 L112.915055,219.581722 L289.83,42.666 L192,42.6666667 L192,1.42108547e-14 L362.666667,1.42108547e-14 Z" id="Combined-Shape">

</path>
        </g>
    </g>
</svg></span>`,
      sender: "BOT",
      editable: false,
    });
  }

  const driverNote = [] as MessageType[];
  if (rideOptions.driverNote) {
    driverNote.push({
      id: Math.random(),
      content: `<b>Driver instructions:</b> ${rideOptions.driverNote}`,
      sender: "BOT",
      editable: false,
    });
  }

  return [
    ...responsePart1,
    ...flightMessage,
    ...responsePart2,
    ...driverNote,
    priceMessage,
  ];
};
