import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import posthog from "posthog-js";

const PROD_POSTHOG_API_KEY = "phc_rhml4NydJhZcIoXAqtKPbwocTKX0IG5BIxwUfwKLbZv";
const DEV_POSTHOG_API_KEY = "phc_wqNKghTigTtgHgGtFGalUYZB38ozp5zZSnTqVYCNW6B";

export const isDev =
  window.location.href.indexOf("https://dev.davel") === 0 ||
  window.location.href.indexOf("http://localhost") === 0;
posthog.init(isDev ? DEV_POSTHOG_API_KEY : PROD_POSTHOG_API_KEY, {
  api_host: "https://us.i.posthog.com",
  person_profiles: "always", // or 'always' to create profiles for anonymous users as well
});

let vagentDiv = document.getElementById("vagent");
const miniBookerDiv = document.getElementById("mini-booker");
let timeout = 250;

const isRidePage = window.location.pathname.indexOf("/ride/") === 0;
if (isRidePage || !miniBookerDiv) {
  console.log("VAgent render prevented. Exiting...");
} else {
  if (!vagentDiv) {
    vagentDiv = document.createElement("div");
    vagentDiv.id = "vagent";
    document.body.appendChild(vagentDiv);
    timeout = 2500;
  }

  const root = ReactDOM.createRoot(vagentDiv);

  // const queryParameters = new URLSearchParams(window.location.search);
  // const uri = window.location.href;

  setTimeout(
    () =>
      root.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>,
      ),
    timeout,
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
