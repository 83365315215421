import "./number-of-passengers-input.css";
import { useState } from "react";
import { Button } from "./Button";

export type NumberOfPassengersInputInputType = {
  setNumberOfPassengers: (instructions: number) => void;
  numberOfPassengers: number;
  isLoading: boolean;
};

export const NumberOfPassengersInput: React.FC<
  NumberOfPassengersInputInputType
> = ({
  setNumberOfPassengers: submitNumberOfPassengers,
  numberOfPassengers,
  isLoading,
}) => {
  const [inputValue, setInputValue] = useState(numberOfPassengers);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(parseInt(event.target.value, 10) || 1);
  }

  return (
    <div className="pax-number-input-block">
      <Button
        onClick={() => setInputValue(inputValue - 1)}
        className="pax-number-button"
        type="secondary"
        disabled={inputValue <= 1}
        text={`-`}
        width="auto"
      />
      <input
        type="text"
        className="number-of-pax-input"
        value={inputValue}
        onChange={handleChange}
        disabled={true}
      />
      <Button
        onClick={() => setInputValue(inputValue + 1)}
        className="pax-number-button"
        type="secondary"
        disabled={inputValue > 5}
        text={`+`}
        width="auto"
      />
      <span className="pax-number-spacer">&nbsp;</span>
      <Button
        type="primary"
        width="full"
        disabled={isLoading}
        text={"Confirm"}
        onClick={
          isLoading ? () => {} : () => submitNumberOfPassengers(inputValue)
        }
      />
    </div>
  );
};
