// Flow should be like this:
// 1. Ask user to proceed. If user agrees we ask to input point A
// 2. User inputs point A and we ask to input point B
// 3. User inputs point B and we ask to pick date and time
// 4. User picks date and time and we ask to pick car class
// 5. User picks car class and we ask to confirm booking

import { sendSmartLookEvent } from "./SmartLook";

export enum BookingFlowState {
  INITIAL,
  ASK_FOR_LOGIN,
  EDIT_POINT_A,
  INPUT_POINT_A,
  INPUT_POINT_A_AIRPORT,
  INPUT_POINT_B,
  INPUT_POINT_B_AIRPORT,
  ENTER_NUMBER_OF_PASSENGERS,
  PICK_DATE_TIME,
  AIRPORT_PICK_DATE_TIME,
  FLIGHT_ARRIVAL_DATE,
  FLIGHT_DEPARTURE_DATE,
  AIRLINE_CODE_INPUT,
  FLIGHT_NUMBER_INPUT,
  PICK_CAR_CLASS,
  CONFIRM_BOOKING,
  EDIT_BOOKING,
  FINISHED,
  GET_A_RECEIPT,
  DRIVER_INSTRUCTIONS,
  CLOSE,
}

let currentState = BookingFlowState.INITIAL;

export const nextState = (stateOverride?: BookingFlowState): string[] => {
  let userDetails = sessionStorage.getItem("auth_user_details");
  console.log(">>>> userDetails", { userDetails });
  if (stateOverride) currentState = stateOverride;

  sendSmartLookEvent("vagent-state", { status: statusToText(currentState) });
  let parsedUserDetails;
  try {
    parsedUserDetails = JSON.parse(userDetails || "");
  } catch (e) {
    userDetails = null;
    console.debug("Error parsing user details", e);
  }

  switch (currentState) {
    case BookingFlowState.INITIAL:
      console.log(
        "Ask user to proceed. If user agrees we ask to input point A",
      );
      if (userDetails) {
        currentState = BookingFlowState.INPUT_POINT_A;
        return [
          `Welcome back, ${parsedUserDetails.first_name}! Let's get your ride booked.`,
          "Where should we pick you up?",
        ];
      } else currentState = BookingFlowState.ASK_FOR_LOGIN;
      return [
        "Do you have an account?",
        "If yes, please log in to continue. Otherwise, you can still book a ride without logging in.",
      ];

    case BookingFlowState.ASK_FOR_LOGIN:
      console.log("Ask user to login");
      currentState = BookingFlowState.INPUT_POINT_A;
      return ["OK, let's get started", "Where should we pick you up?"];

    case BookingFlowState.EDIT_POINT_A:
      console.log("Skip activities and ask to edit point A");
      currentState = BookingFlowState.INPUT_POINT_A;
      return ["Please input your pickup address"];
    case BookingFlowState.INPUT_POINT_A:
      console.log("User inputs point A and we ask to input point B");
      currentState = BookingFlowState.INPUT_POINT_B;
      return ["Got it! Now, please enter your destination."];

    case BookingFlowState.INPUT_POINT_B:
      console.log("User inputs point B and we ask to pick date and time");
      currentState = BookingFlowState.PICK_DATE_TIME;
      return [
        "Thanks!",
        "When would you like to be picked up? Please enter the date and time.",
      ];

    case BookingFlowState.INPUT_POINT_A_AIRPORT:
      console.log("Point A is airport, ask for flight arrival date");
      currentState = BookingFlowState.FLIGHT_ARRIVAL_DATE;
      return ["OK", "When is your flight arriving?"];

    case BookingFlowState.INPUT_POINT_B_AIRPORT:
      console.log("Point A is airport, ask for flight arrival date");
      currentState = BookingFlowState.FLIGHT_DEPARTURE_DATE;
      return ["Thanks", "What date is your flight?"];

    case BookingFlowState.FLIGHT_ARRIVAL_DATE:
      console.log("User inputs flight arrival date");
      currentState = BookingFlowState.AIRLINE_CODE_INPUT;
      return ["OK", "Please proceed with inputing your airline code."];

    case BookingFlowState.FLIGHT_DEPARTURE_DATE:
      currentState = BookingFlowState.AIRLINE_CODE_INPUT;
      return [
        "OK",
        "What is your airline code?",
        "You can input a airline name as well",
      ];

    case BookingFlowState.AIRLINE_CODE_INPUT:
      console.log("User inputs airline code and flight number");
      currentState = BookingFlowState.FLIGHT_NUMBER_INPUT;
      return [
        "Start typing your flight number",
        "And pick the correct one from the results",
      ];

    case BookingFlowState.FLIGHT_NUMBER_INPUT:
      console.log("User inputs airline code and flight number");
      currentState = BookingFlowState.AIRPORT_PICK_DATE_TIME;
      return ["Great!", "When do you want us to pick you up?"];

    case BookingFlowState.AIRPORT_PICK_DATE_TIME:
      console.log("User picks date and time and we ask to pick car class");
      currentState = BookingFlowState.ENTER_NUMBER_OF_PASSENGERS;
      return ["Select the number of passengers"];

    case BookingFlowState.PICK_DATE_TIME:
      console.log("User picks date and time and we ask to pick car class");
      currentState = BookingFlowState.ENTER_NUMBER_OF_PASSENGERS;
      return ["Select the number of passengers"];

    case BookingFlowState.ENTER_NUMBER_OF_PASSENGERS:
      console.log("User inputs number of passengers");
      currentState = BookingFlowState.PICK_CAR_CLASS;
      return [
        "Awesome!",
        "Choose your car for the ride. See prices below for each option.",
      ];

    case BookingFlowState.PICK_CAR_CLASS:
      console.log("User picks car class and we ask to confirm booking");
      currentState = BookingFlowState.DRIVER_INSTRUCTIONS;
      return [
        "Do you have any special instructions for the driver? (Optional)",
      ];

    case BookingFlowState.DRIVER_INSTRUCTIONS:
      console.log("User inputs driver instructions");
      currentState = BookingFlowState.CONFIRM_BOOKING;
      return [
        "All set!",
        "Please review your ride details below and proceed to secure payment to complete your booking.",
      ];

    case BookingFlowState.CONFIRM_BOOKING:
      console.log("User confirms booking");
      currentState = BookingFlowState.EDIT_BOOKING;
      return ["What would you like to update?"];

    case BookingFlowState.EDIT_BOOKING:
      console.log("User confirms booking");
      currentState = BookingFlowState.FINISHED;
      return ["Thank you. You can proceed to the payment page"];

    case BookingFlowState.FINISHED:
      console.log("Booking completed");
      return ["Booking completed"];

    case BookingFlowState.GET_A_RECEIPT:
      console.log("Get a receipt page");
      const receiptsForSignedInUser = [] as string[];
      if (userDetails) {
        receiptsForSignedInUser.push(
          `As well as in your Account center - <a href="/account/rides">Rides page</a>`,
        );
      }
      return [
        "Sure thing!",
        `You can download receipts for your bookings on the "Get a Receipt" page.`,
        ...receiptsForSignedInUser,
      ];
    case BookingFlowState.CLOSE:
      console.log("Close the chat");
      currentState = BookingFlowState.INITIAL;
      return ["OK, I'm closing the chat"];
  }
};

export const getCurrentState = () => currentState;
export const getEditState = (state: BookingFlowState) => {
  switch (state) {
    case BookingFlowState.INPUT_POINT_A:
    case BookingFlowState.INPUT_POINT_A_AIRPORT:
      return BookingFlowState.EDIT_POINT_A;
    case BookingFlowState.INPUT_POINT_B:
    case BookingFlowState.INPUT_POINT_B_AIRPORT:
      return BookingFlowState.INPUT_POINT_A;
    case BookingFlowState.PICK_DATE_TIME:
      return BookingFlowState.INPUT_POINT_B;
    case BookingFlowState.ENTER_NUMBER_OF_PASSENGERS:
      return BookingFlowState.PICK_DATE_TIME;
    case BookingFlowState.PICK_CAR_CLASS:
      return BookingFlowState.ENTER_NUMBER_OF_PASSENGERS;
    case BookingFlowState.CONFIRM_BOOKING:
      return BookingFlowState.DRIVER_INSTRUCTIONS;
    case BookingFlowState.DRIVER_INSTRUCTIONS:
      return BookingFlowState.PICK_CAR_CLASS;
    case BookingFlowState.AIRPORT_PICK_DATE_TIME:
      return BookingFlowState.FLIGHT_NUMBER_INPUT;
    case BookingFlowState.FLIGHT_NUMBER_INPUT:
      return BookingFlowState.AIRLINE_CODE_INPUT;
    case BookingFlowState.AIRLINE_CODE_INPUT:
      return BookingFlowState.FLIGHT_ARRIVAL_DATE;
    case BookingFlowState.FLIGHT_ARRIVAL_DATE:
      return BookingFlowState.INPUT_POINT_A_AIRPORT;
    case BookingFlowState.FLIGHT_DEPARTURE_DATE:
      return BookingFlowState.INPUT_POINT_B_AIRPORT;
    default:
      return BookingFlowState.INITIAL;
  }
};

export const statusToText = (status: BookingFlowState) => {
  switch (status) {
    case BookingFlowState.INITIAL:
      return "initial";
    case BookingFlowState.ASK_FOR_LOGIN:
      return "ASK_FOR_LOGIN";
    case BookingFlowState.EDIT_POINT_A:
      return "EDIT_POINT_A";
    case BookingFlowState.INPUT_POINT_A:
      return "INPUT_POINT_A";
    case BookingFlowState.INPUT_POINT_A_AIRPORT:
      return "INPUT_POINT_A_AIRPORT";
    case BookingFlowState.INPUT_POINT_B:
      return "INPUT_POINT_B";
    case BookingFlowState.INPUT_POINT_B_AIRPORT:
      return "INPUT_POINT_B_AIRPORT";
    case BookingFlowState.ENTER_NUMBER_OF_PASSENGERS:
      return "ENTER_NUMBER_OF_PASSENGERS";
    case BookingFlowState.PICK_DATE_TIME:
      return "PICK_DATE_TIME";
    case BookingFlowState.AIRPORT_PICK_DATE_TIME:
      return "AIRPORT_PICK_DATE_TIME";
    case BookingFlowState.FLIGHT_ARRIVAL_DATE:
      return "FLIGHT_ARRIVAL_DATE";
    case BookingFlowState.FLIGHT_DEPARTURE_DATE:
      return "FLIGHT_DEPARTURE_DATE";
    case BookingFlowState.AIRLINE_CODE_INPUT:
      return "AIRLINE_CODE_INPUT";
    case BookingFlowState.FLIGHT_NUMBER_INPUT:
      return "FLIGHT_NUMBER_INPUT";
    case BookingFlowState.PICK_CAR_CLASS:
      return "PICK_CAR_CLASS";
    case BookingFlowState.CONFIRM_BOOKING:
      return "CONFIRM_BOOKING";
    case BookingFlowState.EDIT_BOOKING:
      return "EDIT_BOOKING";
    case BookingFlowState.FINISHED:
      return "FINISHED";
    case BookingFlowState.GET_A_RECEIPT:
      return "GET_A_RECEIPT";
    case BookingFlowState.DRIVER_INSTRUCTIONS:
      return "DRIVER_INSTRUCTIONS";
    case BookingFlowState.CLOSE:
      return;
    default:
      console.log("Undefined status", status);
      return "unknown";
  }
};
